* {
    margin: 0px;
    padding: 0px;
 }

html, body
{
    height: 100%;
    position: fixed;
}

.spaced-button {
    margin-left: 5px;
    margin-right: 5px;
}

.material-font-family {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.padded-text {
    padding: 10px;
}

.well-padded-text {
    padding: 30px;
}

.release-image {
    box-shadow: 4px 4px 14px -1px #9a9a9a;
}

.release-image-small {
    box-shadow: 2px 2px 7px -1px #9a9a9a;
}