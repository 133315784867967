#track-header {
    grid-area: track-header;
    /* background-color: #ECF7F9; */
}

#track-browser {
    grid-area: track-browser;
    /* background-color: CornflowerBlue; */
}

#track-details {
    grid-area: track-details;
    /* background-color: Lavender; */
}

#tracks-page-wrapper {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin: 5px;
    /* background: #eaeaea; */
    /* border-radius: 4px;
    border: 1px solid #ccc; */

    display: grid;
    grid-template-columns: 10px 1fr 5px 1fr 10px;
    grid-template-rows: 40px 10px auto 10px;
    grid-template-areas:
        "track-header track-header . track-details ."
        ". . . track-details ."
        ". track-browser . track-details ."
        ". . . . .";
}

#track-details-narrow {
    grid-area: track-details-narrow;
    /* background-color: orange; */
    /* overflow-y: auto;
    height: 1000px; */
}
#track-details-narrow-inside {
    /* grid-area: track-details-narrow; */
    /* background-color: green; */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* height: 200px; */
}

#tracks-narrow-page-wrapper {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin: 5px;
    display: grid;
    grid-template-columns: 5px 1fr 5px;
    grid-template-rows: 1fr;
    grid-template-areas: ". track-details-narrow .";
}