#track-table-wrapper {
    min-height: 300px;
    margin: 5px;
}
.ReactVirtualized__Table *:focus {
    outline: none;
}

.ReactVirtualized__Table__headerRow {
    text-align: left;
    /* background-color: #eaeaea; */
    /* border-bottom: 1px solid #3f51b5 !important; */
}

.ReactVirtualized__Table__row {
    text-align: left;
    font-size: 0.9em;
    border-bottom: 1px solid #eaeaea !important;
}

.ReactVirtualized__Table__row:active {
    background-color: '#DBDBDB';
}

.ReactVirtualized__Table__row.selected-row {
    background-color: #f9fab0 !important;
}

.ReactVirtualized__Table__row.selected-row:hover {
    background-color: #f5f780 !important;
}
.ReactVirtualized__Table__row.selected-row:active {
    background-color: #dee10e !important;
}
.ReactVirtualized__Table__row.not-visible {
    color: white;
    background-color: #aaa;
}

.ReactVirtualized__Table__row.easter-egg {
    /* color: white; */
    background-color: #fff5fa;
}