#track-browser-wrapper {
    /* width: calc(100% - 20px); */
    height: calc(100% - 20px);
    margin: 5px;
    /* background: PaleTurquoise; */
    border-radius: 4px;
    /* border: 1px solid #d3d4b4; */

}

#track-browser-artist-group-form-control {
    width: 200px;
}