.flex-row-space-between-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-row-start-start {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
}

.flex-row-center-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-row-space-evenly-center {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.flex-row-start-center {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: 'red'
}

.flex-row-end-center {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.flex-row-start-center-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.flex-row-space-between-start {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.flex-column-start-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-column-start-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.flex-column-reverse-start-center {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
}

.flex-column-space-between-start {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}